import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne, { TweenOneGroup } from 'rc-tween-one';
import BannerAnim, { Element } from 'rc-banner-anim';
import { isImg } from './utils';
import 'rc-banner-anim/assets/index.css';

const BgElement = Element.BgElement;
class Banner extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = dataSource.BannerAnim.children.map((item, i) => {
      const elem = item.BannerElement;
      const elemClassName = elem.className;
      delete elem.className;
      const bg = item.bg;
      const textWrapper = item.textWrapper;
      const title = item.title;
      const content = item.content;
      const button = item.button;
      const page = item.page;
      const follow = !isMobile
        ? {
          delay: 1000,
          minMove: 0.1,
          data: [
            {
              id: `bg${i}`,
              value: 15,
              type: 'x',
            },
            { id: `wrapperBlock${i}`, value: -15, type: 'x' },
          ],
        }
        : null;
      return (
        <Element
          key={i.toString()}
          followParallax={follow}
          {...elem}
          prefixCls={elemClassName}
        >
          <BgElement key="bg" {...bg} id={`bg${i}`} />
          <div {...page}>
            <QueueAnim
              type={['bottom', 'top']}
              delay={200}
              key="text"
              {...textWrapper}
              id={`wrapperBlock${i}`}
            >
              <div key="logo" {...title}>
                {typeof title.children === 'string' &&
                  title.children.match(isImg) ? (
                    <img src={title.children} width="100%" alt="img" />
                  ) : (
                    title.children
                  )}
              </div>
              <div key="content" {...content}>
                {content.children}
              </div>
              <div key="logo-1" {...title}>
                渠道专员
              </div>
              <div key="content-1" {...content}>
                <ul>
                  <li>熟悉资本市场和产品</li>
                  <li>谦虚谨慎，具有团队精神和主动思考能力</li>
                  <li>具有快速学习能力和抗压能力，做事认真负责</li>
                  <li>数理功底扎实，善于处理各种信息</li>
                  <li>熟练使用Office系统和办公设备</li>
                  <li>月薪1w+（具体细节视情况而定）</li>
                </ul>
              </div>
              <div key="logo-3" {...title}>
                联系我们
              </div>
              <div key="content-3" {...content}>
                <div>
                  联系方式：0591-83839898
                  </div>
                <div>
                  招聘负责人电话：18650309718
                  </div>
                <div>
                  公司地址：福州市仓山区闽江世纪金源购物中心创富中心大厦14层（自贸试验区内）
                  </div>
              </div>
            </QueueAnim>
          </div>
        </Element>
      );
    });
    return (
      <div {...props} {...dataSource.wrapper}>
        <TweenOneGroup
          key="bannerGroup"
          enter={{ opacity: 0, type: 'from' }}
          leave={{ opacity: 0 }}
          component=""
        >
          <BannerAnim key="BannerAnim" {...dataSource.BannerAnim}>
            {childrenToRender}
          </BannerAnim>
        </TweenOneGroup>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner2-icon"
          style={{ bottom: 40 }}
          key="icon"
        >
        </TweenOne>
      </div>
    );
  }
}

export default Banner;
