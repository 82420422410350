import React, { Component } from "react";
import "./App.css";
import { HashRouter as Router, Route } from "react-router-dom";
import { enquireScreen } from "enquire-js";
import { Modal, Collapse } from "antd";

import Header from "./NewHome/Nav0";
import { Nav00DataSource } from "./NewHome/data.source.js";
import Footer from './NewHome/Footer1';
import Home from './NewHome';
import JoinUs from './JoinUs';
import Login from './Login'

import {
  Footer10DataSource
} from './NewHome/data.source.js';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      visible: false,
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    this.info();
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    }, () => {
      localStorage.setItem("bfytz-accepted", "1");
    });
  };

  mobileContent = () => {
    return (
      <div>
        <p>
          在继续浏览本公司网站前，请您细阅此重要提示，同意本协议继续浏览，否则请关闭本网页
        </p>

        <Collapse ghost>
          <Collapse.Panel header="阅读协议全文" key="1">
            <p>
              在继续浏览本公司网站前，请您确认您或您所代表的机构是一名“合格投资者”。“合格投资者”是指：（1）根据中华人民共和国境内法律法规，尤其是中国证监会《私募投资基金监督管理暂行办法》所规定的“合格投资者”，或（2）根据中华人民共和国香港特别行政区《证券及期货条例》所定义的“专业投资者”，或（3）根据美国的证券和投资法规所定义的“合格买方”或“有资格投资者”，或（4）其他任何国家和地区的证券和投资法规所规定的有资格投资于对冲基金或类似的集合理财计划的专业投资者。如您或您所代表的机构对自身是否属于“合格投资者”存有疑问的，请咨询您或您所代表机构聘请的律师、法律顾问或其他法律专业人员。
            </p>
            <p>
              您若继续阅览本网页所载资料，将被视为您声明及保证为“合格投资者”，并将遵守对您适用的司法区域的有关法例及法规。
            </p>
            <p>
              本网站所载的资料仅供参考，并不构成广告或分销、销售要约，或招揽买入任何证券、基金或其他投资工具的邀请或要约。
            </p>
            <p>
              投资涉及风险，投资者应详细审阅基金的发售文件以获取进一步资料及了解有关投资于新兴市场所涉及之风险因素，并寻求适当的专业投资和税务咨询意见。基金的股份或单位价格及其收益可升可跌，而过往的基金表现数据并不预示基金未来的表现。
              本网站所提供的资料并非投资建议或税务咨询意见，投资者不应依赖本网站所提供的资料作出投资决策。
            </p>
            <p>
              福州暴风眼投资管理有限公司或其关联公司、董事、监事、高级管理人员及任何雇员不对本网站所提供资料的准确性、充足性或完整性作出任何保证，也不对于本网站内所提供资料之任何错误或遗漏承担任何法律责任。您在任何情况下均不得复印、复制或再分发本网站资料的全部或其任何部分。
            </p>
            <p>
              本网站所刊载的所有资料福州暴风眼投资管理有限公司可予以更改或修订而毋须前事通知，福州暴风眼投资管理有限公司并不承诺定期更新本网页。
            </p>
            <p>
              与本网站所载资料有关的所有版权、专利权、知识产权及其他产权均为福州暴风眼投资管理有限公司或其关联公司所有。本公司概不向浏览该资料的人士发出、转让或以任何方式转移任何种类的权利。
            </p>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  };

  pcContent = () => {
    return (
      <div>
        <p>
          在继续浏览本公司网站前，请您细阅此重要提示，同意本协议继续浏览，否则请关闭本网页
        </p>

        <p>
          在继续浏览本公司网站前，请您确认您或您所代表的机构是一名“合格投资者”。“合格投资者”是指：（1）根据中华人民共和国境内法律法规，尤其是中国证监会《私募投资基金监督管理暂行办法》所规定的“合格投资者”，或（2）根据中华人民共和国香港特别行政区《证券及期货条例》所定义的“专业投资者”，或（3）根据美国的证券和投资法规所定义的“合格买方”或“有资格投资者”，或（4）其他任何国家和地区的证券和投资法规所规定的有资格投资于对冲基金或类似的集合理财计划的专业投资者。如您或您所代表的机构对自身是否属于“合格投资者”存有疑问的，请咨询您或您所代表机构聘请的律师、法律顾问或其他法律专业人员。
        </p>
        <p>
          您若继续阅览本网页所载资料，将被视为您声明及保证为“合格投资者”，并将遵守对您适用的司法区域的有关法例及法规。
        </p>
        <p>
          本网站所载的资料仅供参考，并不构成广告或分销、销售要约，或招揽买入任何证券、基金或其他投资工具的邀请或要约。
        </p>
        <p>
          投资涉及风险，投资者应详细审阅基金的发售文件以获取进一步资料及了解有关投资于新兴市场所涉及之风险因素，并寻求适当的专业投资和税务咨询意见。基金的股份或单位价格及其收益可升可跌，而过往的基金表现数据并不预示基金未来的表现。
          本网站所提供的资料并非投资建议或税务咨询意见，投资者不应依赖本网站所提供的资料作出投资决策。
        </p>
        <p>
          福州暴风眼投资管理有限公司或其关联公司、董事、监事、高级管理人员及任何雇员不对本网站所提供资料的准确性、充足性或完整性作出任何保证，也不对于本网站内所提供资料之任何错误或遗漏承担任何法律责任。您在任何情况下均不得复印、复制或再分发本网站资料的全部或其任何部分。
        </p>
        <p>
          本网站所刊载的所有资料福州暴风眼投资管理有限公司可予以更改或修订而毋须前事通知，福州暴风眼投资管理有限公司并不承诺定期更新本网页。
        </p>
        <p>
          与本网站所载资料有关的所有版权、专利权、知识产权及其他产权均为福州暴风眼投资管理有限公司或其关联公司所有。本公司概不向浏览该资料的人士发出、转让或以任何方式转移任何种类的权利。
        </p>
      </div>
    );
  };

  info = () => {
    const accepted = localStorage.getItem("bfytz-accepted");
    if (!accepted) {
      this.setState({visible: true})
    } 
  };

  render() {
    return (
      <Router>
        <div>
          <Header dataSource={Nav00DataSource} isMobile={this.state.isMobile} />
          <Route exact path="/" component={Home} />
          <Route exact path="/join-us" component={JoinUs} />
          <Route exact path="/login" component={Login} />
          <Footer dataSource={Footer10DataSource} isMobile={this.state.isMobile} />
        </div>
        <Modal
          title="重要提示"
          visible={this.state.visible}
          onOk={this.handleOk}
          okText="同意接受"
          closable={false}
          onCancel={() => window.location.assign('https://baidu.com')}
          cancelText="拒绝并且关闭网页"
        >
          {this.state.isMobile ? this.mobileContent() : this.pcContent()}
        </Modal>
      </Router>
    );
  }
}
export default App;
