import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    // children: 'https://storage.bfytz.cn/storm-article-file/9f41ac557d44cba0b1a605fb072db579.jpg',
    children: 'https://storage.bfytz.cn/storm-article-file/2269aa84c7c7476ad6310d1ebad5cffb.jpg'
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: 'about',
          children: [
            {
              children: (
                <span>
                  <p>关于我们</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: 'login',
          children: [
            {
              children: (
                <span>
                  <p>客户登录</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: 'join-us',
          children: [
            {
              children: (
                <span>
                  <p>加入我们</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
}
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg4' },
        title: {
          className: 'banner1-title',
          // children:
          //   'https://storage.bfytz.cn/imgs/1605255370604.jpg',
        },
        content: {
          className: 'banner1-content khfz3lboqz-editor_css',
          // children: '',
        },
      },
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg5' },
        title: {
          className: 'banner1-title',
          // children:
          //   'https://storage.bfytz.cn/imgs/1605255370604.jpg',
        },
        content: {
          className: 'banner1-content khfz3lboqz-editor_css',
          // children: '',
        },
      },
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner1-title',
          // children:
          //   'https://storage.bfytz.cn/imgs/1605255370604.jpg',
        },
        content: {
          className: 'banner1-content khfz3lboqz-editor_css',
          // children: '',
        },
      }, 
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg3' },
        title: {
          className: 'banner1-title',
          // children:
          //   'https://storage.bfytz.cn/imgs/1605255370604.jpg',
        },
        content: {
          className: 'banner1-content khfz3lboqz-editor_css',
          children: '致力于安心持有',
        },
      },

      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          // children:
          //   'https://storage.bfytz.cn/imgs/1605255370604.jpg',
        },
        content: {
          className: 'banner1-content khfz3lboqz-editor_css',
          children: '致力于收益可持续',
        },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg2' },
        title: {
          className: 'banner1-title',
          // children:
          //   'https://storage.bfytz.cn/imgs/1605255370604.jpg',
        },
        content: {
          className: 'banner1-content khfz3lboqz-editor_css',
          children: '致力于强风控低回撤',
        },
      },

      
    ],
  },
};

export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '蚂蚁金融云提供专业的服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于阿里云计算强大的基础资源',
      },
    ],
  },
  img: {
    children: 'https://storage.bfytz.cn/imgs/1605516015182.jpg',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: { className: 'content6-title', children: '技术' },
        content: {
          className: 'content6-content',
          children:
            '丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: { className: 'content6-title', children: '融合' },
        content: {
          className: 'content6-content',
          children:
            '解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: { className: 'content6-title', children: '开发' },
        content: {
          className: 'content6-content',
          children:
            '符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。',
        },
      },
    ],
  },
};


export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 12,
        className: 'block',
        title: {
          className: 'logo',
          children: 'https://storage.bfytz.cn/storm-article-file/2269aa84c7c7476ad6310d1ebad5cffb.jpg'
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: '本网站所载全部资料的版权及其他任何权利均为暴风眼投资所有，未经许可不得使用，不得转载、转播、摘编。',
            },
            {name: 'a', children: '使用条款', href: "https://www.bfytz.cn/html/useTerms.html", target: '_blank'},
            {name: 'a', children: '隐私政策', href: "https://www.bfytz.cn/html/privacyPolicy.html", target: '_blank'},
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 12,
        className: 'block',
        title: { children: '福州暴风眼投资管理有限公司' },
        childWrapper: {
          children: [
            {name: 'div', children: '地址: 福州市仓山区闽江世纪金源购物中心创富中心大厦14层（自贸试验区内）'},
            {name: 'div', children: '电话: 0591-83839898'},
            {name: 'div', children: '邮箱: client@bfytz.cn'},
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
      ©2020 <a href="/#">暴风眼投资有限公司</a> 闽ICP备 202002015-2 号 All Rights Reserved
      </span>
    ),
  },
};
