import React from "react";
import TweenOne from "rc-tween-one";
import { Row, Col } from "antd";
import QueueAnim from "rc-queue-anim";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { Typography } from "antd";
import { Element } from "react-scroll";

const { Title, Text } = Typography;

class Content7 extends React.Component {
  getBlockChildren = (data) =>
    data.map(($item) => {
      const { ...item } = $item;
      const { title, img, content } = item;
      ["title", "img", "content"].forEach((key) => delete item[key]);
      return (
        <li key={item.name} {...item}>
          <span {...img}>
            <img src={img.children} width="100%" alt="img" />
          </span>
          <h2 {...title}>{title.children}</h2>
          <div {...content}>{content.children}</div>
        </li>
      );
    });

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    const queue = isMobile ? "bottom" : "left";
    const imgAnim = isMobile
      ? {
          y: 30,
          opacity: 0,
          delay: 600,
          type: "from",
          ease: "easeOutQuad",
        }
      : {
          x: 30,
          opacity: 0,
          type: "from",
          ease: "easeOutQuad",
        };
    return (
      <Element {...props} {...dataSource.wrapper} name="about-us">
        <OverPack {...dataSource.OverPack} component={Row}>
          <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            {...dataSource.img}
            component={Col}
          >
            <Title level={2} style={{ color: "white" }}>
              推荐信
            </Title>
            <img src={dataSource.img.children} width="100%" alt="img" />
          </TweenOne>
          <QueueAnim
            key="text"
            type={queue}
            leaveReverse
            ease={["easeOutQuad", "easeInQuad"]}
            {...dataSource.textWrapper}
            component={Col}
          >
            <blockquote>
              <p className="quotation">
                林岑具有非常强大的市场领悟力以及对课堂
                所授的技术分析概念掌握透彻。林岑是一个可
                靠信赖的学生并且对投资充满了强烈的热情。
                他能够充分掌握在不同时间维度下界定市场趋
                势的能力并且能使用核心工具分析趋势。林岑
                拥有对趋势非常正确的理解，这一点是至关重
                要的并且能够耐心地观察等待趋势的演变。
              </p>
              <footer> — David Lundgren 
              <div className="footer-user-description">美国技术分析协会董事</div>
              <div>威灵顿资产管理有限公司负责技术分析副总裁</div>
              <div>特许市场技术分析师，特许金融分析师</div>
              <div>技术分析课程客座教授</div>
              </footer>
            </blockquote>
          </QueueAnim>
          <div style={{ marginTop: "24px" }}>
            <Text
              style={{
                color: "white",
                // fontWeight: "lighter",
                textAlign: "center",
                fontSize: '20px'
              }}
            >
              暴风眼其他投研团队成员来自:University of Notre Dame、Brandeis University、中国人民大学、浙江大学、厦门大学。
            </Text>
          </div>
        </OverPack>
      </Element>
    );
  }
}

export default Content7;
