import React from "react";
import { Typography } from "antd";
const { Title } = Typography;

class Banner5 extends React.PureComponent {
  state = {
    loginState: "notLogin",
  };

  componentDidMount() {
    window.setToLogin = this.setToLogin;
  }

  setToLogin = () => {
    this.setState({
      loginState: "notLogin",
    });
  };

  gotoUserWebsite = (type) => {
    switch (type) {
      case "1":
        window.open("https://pb.gtja.com/equerying/", "_blank");

        break;

      case "2":
        window.open("https://pb.htsc.com.cn/htsc-pos-is/user/login", "_blank");
        break;

      case "3":
        window.open("https://isp.gf.com.cn/newcas/", "_blank");
        break;

      default:
        break;
    }
  };

  renderGuestLogin() {
    return (
      <div className="banner5-guest-login" style={{ flexDirection: "column" }}>
        <Title level={this.props.isMobile ? 3 : 2} style={{ color: "white" }}>
          了解更多资讯请使用微信扫码以下小程序码加入我们
        </Title>
        <div>
          <img
            src="https://storage.bfytz.cn/storm-article-file/29bb8a6f1da2896ff36092a46b4eb61c.png"
            width="430px"
          />
        </div>
      </div>
    );
  }

  renderUserLogin() {
    return (
      <div
        className="banner5-guest-login"
        style={{
          flexDirection: "column",
          maxWidth: this.props.isMobile ? "100%" : "60%",
          margin: "auto",
        }}
      >
        <div style={{ color: "white", fontSize: this.props.isMobile ?  '1.5rem' : '2.5rem', padding: '16px', fontWeight: 'bold' }}>
          尊敬的客户您好，欢迎来到暴风眼投资
        </div>
        <div  style={{ color: "white", fontSize: this.props.isMobile ?  '1rem' : '2rem', padding: '16px', fontWeight: 'bold' }}>
          请选择您所购买产品对应的托管方，登录后即可查询持有份额和产品净值等信息。
          如有疑问可咨询您的产品经理。
        </div>
        <div style={{ paddingBottom: "16px" }}>
          <div
            className="action-button"
            onClick={() => this.gotoUserWebsite("1")}
          >
            国泰君安托管
          </div>
        </div>
        <div style={{ paddingBottom: "16px" }}>
          <div
            className="action-button"
            onClick={() => this.gotoUserWebsite("2")}
          >
            华泰证券托管
          </div>
        </div>
        <div style={{ paddingBottom: "16px" }}>
          <div
            className="action-button"
            onClick={() => this.gotoUserWebsite("3")}
          >
            广发证券托管
          </div>
        </div>
      </div>
    );
  }

  guestLogin = () => {
    this.setState({
      loginState: "guestLogin",
    });
  };

  userLogin = () => {
    this.setState({
      loginState: "userLogin",
    });
  };

  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    return (
      <div
        {...tagProps}
        {...dataSource.wrapper}
        style={{
          paddingLeft: this.props.isMobile ? "0px" : "120px",
          paddingRight: this.props.isMobile ? "0px" : "120px",
        }}
      >
      <div style={{position: 'absolute', top: '16px', right: '32px'}}>
        <img src="https://storage.bfytz.cn/storm-article-file/81be219ae4c08e725a7b964d832074ec.png" width="205px" />
      </div>
        {this.state.loginState === "notLogin" ? (
          <>
            <div className="banner5-title-wrapper">
              <div className="login-button" onClick={this.userLogin}>
                客户登录
              </div>
            </div>
            <div className="banner5-title-wrapper" onClick={this.guestLogin}>
              <div className="login-button">游客登录</div>
            </div>
          </>
        ) : this.state.loginState === "guestLogin" ? (
          this.renderGuestLogin()
        ) : (
          this.renderUserLogin()
        )}
      </div>
    );
  }
}
export default Banner5;
