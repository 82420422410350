import React from 'react'
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: { className: 'banner2-title', children: '行业研究员' },
        content: {
          className: 'banner2-content',
          children: (
            <ul>
              <li>国内211或985毕业/国外世界大学排名前100 硕士及以上学历</li>
              <li>具有基金研究相关工作或实习经验优先</li>
              <li>具有较好的沟通表达能力</li>
              <li>具有较好的书面表达及数据处理能力</li>
            </ul>
          ),
        },
        button: { className: 'banner2-button', children: 'Learn More' },
      },
    ],
  },
};
